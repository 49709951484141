import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import store from "@/store/index";

const routerGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  window.scrollTo(0, 0);

  if (to.meta.protectedRoute && store.getters.isAuthenticated) {
    next();
  } else if (to.meta.protectedRoute && !store.getters.isAuthenticated) {
    next({ name: "HomeIndexVue" });
  } else if (!to.meta.protectedRoute && store.getters.isAuthenticated) {
    next({ name: "AppDashboardIndexVue" });
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "home-index" */ "../views/home/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/login",
    name: "AuthenticationLoginIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-login-index" */ "../views/authentication/login/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery",
    name: "AuthenticationPasswordRecoveryIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-index" */ "../views/authentication/password-recovery/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery-verification/:token",
    name: "AuthenticationPasswordRecoveryVerificationIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-verification-index" */ "../views/authentication/password-recovery-verification/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/dashboard",
    name: "AppDashboardIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-dashboard-index" */ "../views/app/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/transaction-history",
    name: "AppTransactionHistoryIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-transaction-history-index" */ "../views/app/transaction-history/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/account/information",
    name: "AppAccountInformationIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-account-information-index" */ "../views/app/account/information/index.vue"),
    beforeEnter: routerGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;


import { Options, Vue } from "vue-class-component";

// Vendors
import { onClickOutside } from "@vueuse/core";

// Components
import { ElContainer } from "element-plus";

// Store
import Store from "@/store/index";

// Services
import { postRequest } from "@/services/api/request";

@Options({
  components: {
    ElContainer,
  },
  watch: {
    $route() {
      this.isPopupOpened = false;
    },
  },
})
export default class ComponentsNavigationVue extends Vue {
  isLoading = false;
  isPopupOpened = false;

  get getAuthenticationName(): string {
    return Store.getters.getAuthenticationName;
  }

  togglePopup(): void {
    this.isPopupOpened ? (this.isPopupOpened = false) : (this.isPopupOpened = true);
  }

  async logout(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/client/authorization/logout",
      isProtected: true,
      successNotification: "See you soon!",
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then(() => {
      Store.dispatch("setLogout");
      this.$router.push({
        name: "HomeIndexVue",
      });
    });
    this.isLoading = false;
  }

  mounted(): void {
    const NavigationEl: HTMLElement | null = document.querySelector("#Navigation");

    if (NavigationEl) {
      onClickOutside(NavigationEl, () => {
        this.isPopupOpened = false;
      });
    }
  }
}

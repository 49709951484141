
import { Options, Vue } from "vue-class-component";

// Components
import ComponentsNavigationVue from "@/components/navigation.vue";
import ComponentsFooterVue from "@/components/footer.vue";

// Store
import store from "@/store/index";

@Options({
  components: {
    ComponentsNavigationVue,
    ComponentsFooterVue,
  },
})
export default class App extends Vue {
  get isAuthenticated(): boolean {
    return store.getters.isAuthenticated;
  }
}
